import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"

export default function useIsExplorePageShown() {
  const activeOrganization = useActiveOrganization()
  if (!activeOrganization) return false
  return (
    !activeOrganization.isExploreProductTabHidden ||
    activeOrganization.viewerPermissions.has("products.manage")
  )
}
