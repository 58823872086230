import CreateBadgeModalButton from "@/admin/experiences/badges/CreateBadgeModalButton"
import { CreatePathwayFormState } from "@/admin/pathways/hooks/useCreatePathwayForm"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductRegistrationAvailability } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import {
  DiscoFormControl,
  DiscoIcon,
  DiscoIconKinds,
  DiscoInput,
  DiscoSelect,
  DiscoText,
} from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface CreatePathwayFormProps extends TestIDProps {
  form: CreatePathwayFormState
}
type PathwayRegistrationAvailability = Exclude<
  ProductRegistrationAvailability,
  "%future added value"
>

function CreatePathwayForm({
  testid = "CreatePathwayForm",
  form,
}: CreatePathwayFormProps) {
  const classes = useStyles()
  const adminPathwayLabel = useAdminProductLabel("pathway")
  const memberLabel = useLabel("organization_member")

  const REGISTRATION_AVAILABILITY_OPTIONS: Record<
    PathwayRegistrationAvailability,
    {
      title: string
      value: PathwayRegistrationAvailability
      description: string
      icon: DiscoIconKinds
    }
  > = {
    public: {
      title: "Public",
      value: "public",
      description: `Anyone on the internet can register for this ${adminPathwayLabel.singular}.`,
      icon: "globe",
    },
    private: {
      title: `${memberLabel.plural} Only`,
      value: "private",
      description: `Only ${memberLabel.plural} can register for this ${adminPathwayLabel.singular}.`,
      icon: "users",
    },
    hidden: {
      title: "Hidden",
      value: "hidden",
      description: `Hide this ${adminPathwayLabel.singular} from the Explore page so no new registrations are allowed.`,
      icon: "lock",
    },
  }

  return (
    <Form testid={testid}>
      <DiscoFormControl title={"Badge and Title"} errorMessages={form.errorsByField.name}>
        <DiscoInput
          value={form.state.name}
          onChange={handleNameChange}
          fullWidth
          data-testid={`${testid}.name`}
          padding={"0 10px 0 0"}
          startAdornment={<CreateBadgeModalButton badge={form.state.badge} />}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          placeholder={`${adminPathwayLabel.singular} Title`}
        />
      </DiscoFormControl>
      <DiscoFormControl
        title={"Description"}
        errorMessages={form.errorsByField.description}
        titleTooltip={{
          label: `This text is used on the ${adminPathwayLabel.singular} cards displayed on the ‘Explore’ page where ${memberLabel.plural} can discover your ${adminPathwayLabel.singular}. Your ${adminPathwayLabel.singular} Registration Page can provide a more robust description.`,
        }}
      >
        <DiscoInput
          data-testid={`${testid}.description`}
          value={form.state.description}
          onChange={handleDescriptionChange}
          placeholder={`A condensed description for this ${adminPathwayLabel.singular}`}
          minRows={3}
          multiline
        />
      </DiscoFormControl>

      <DiscoFormControl title={"Access"}>
        <DiscoSelect
          testid={`${testid}.registration-availability`}
          autoComplete={false}
          value={form.state.registrationAvailability}
          onChange={(v) => {
            form.state.registrationAvailability = v as PathwayRegistrationAvailability
          }}
          options={Object.values(REGISTRATION_AVAILABILITY_OPTIONS).map((option) => ({
            value: option.value,
            title: option.title,
          }))}
          renderOption={({ value }) => {
            const option = REGISTRATION_AVAILABILITY_OPTIONS[value]
            return (
              <div className={classes.option}>
                <DiscoIcon icon={option.icon} />
                <div>
                  <DiscoText variant={"body-md-600"}>{option.title}</DiscoText>
                  <DiscoText variant={"body-sm"} color={"text.secondary"}>
                    {option.description}
                  </DiscoText>
                </div>
              </div>
            )
          }}
          renderValue={({ value }) => {
            const option = REGISTRATION_AVAILABILITY_OPTIONS[value]
            return <DiscoText variant={"body-md"}>{option.title}</DiscoText>
          }}
          disableClearable
        />
      </DiscoFormControl>
    </Form>
  )

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.name = event.target.value
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.description = event.target.value
  }
}

const useStyles = makeUseStyles((theme) => ({
  option: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default observer(CreatePathwayForm)
