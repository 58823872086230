import { ModuleSelect_OrganizationQuery } from "@/admin/content-library/actions/__generated__/ModuleSelect_OrganizationQuery.graphql"
import ModuleDropdownItem from "@/content-usage/modules/ModuleDropdownItem"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoInputSkeleton, DiscoSelect } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

type Props = TestIDProps & {
  value: GlobalID
  onSelect: (contentId: GlobalID) => void
  placeholder?: string
}

function ModuleSelect(props: Props) {
  const { testid = "ModuleSelect", value, onSelect } = props

  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()!

  const { organization, product } = useLazyLoadQuery<ModuleSelect_OrganizationQuery>(
    graphql`
      query ModuleSelect_OrganizationQuery(
        $productId: ID!
        $organizationId: ID!
        $isProduct: Boolean!
        $isOrganization: Boolean!
        $entities: [ContentUsageEntity!]
      ) {
        organization: node(id: $organizationId) @include(if: $isOrganization) {
          ... on Organization {
            modules(excludeProductModules: true, entities: $entities) {
              edges {
                node {
                  id
                  name
                  ...ModuleDropdownItem_ContentFragment
                }
              }
            }
          }
        }
        product: node(id: $productId) @include(if: $isProduct) {
          ... on Product {
            modules(entities: $entities) {
              edges {
                node {
                  id
                  name
                  ...ModuleDropdownItem_ContentFragment
                }
              }
            }
          }
        }
      }
    `,
    {
      productId: activeProduct?.id || "",
      organizationId: activeOrganization.id,
      entities: ["collection"],
      isOrganization: Boolean(!activeProduct),
      isProduct: Boolean(activeProduct),
    },
    { fetchPolicy: "store-and-network" }
  )

  const modules = activeProduct
    ? Relay.connectionToArray(product?.modules)
    : Relay.connectionToArray(organization?.modules)

  return (
    <DiscoSelect
      testid={`${testid}.select`}
      placeholder={"Select content"}
      value={value}
      options={modules.map((module) => ({
        value: module.id,
        title: module.name || "",
        moduleKey: module,
      }))}
      renderOption={(option) => {
        return (
          <ModuleDropdownItem
            moduleKey={option.moduleKey}
            testid={`${testid}.${option.title}`}
          />
        )
      }}
      onChange={(id) => onSelect(id!)}
    />
  )
}

function ModuleSelectSkeleton() {
  return <DiscoInputSkeleton />
}

export default Relay.withSkeleton({
  component: ModuleSelect,
  skeleton: ModuleSelectSkeleton,
})
