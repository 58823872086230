import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { FC } from "react"
import { useConnectSSODrawer } from "./useConnectSSODrawer"

const ConnectSSODrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "connect-SSO-drawer-content" */ "@/admin/integrations/sso/ConnectSSODrawerContent"
    )
)

const ConnectSSODrawer: FC = () => {
  const { isOpen, close } = useConnectSSODrawer()
  const classes = useStyles()

  return (
    <DiscoDrawer
      open={isOpen}
      onClose={close}
      anchor={"right"}
      testid={"ConnectSSODrawer"}
      containerClasses={{ drawerContainer: classes.drawerContainer }}
      size={"xxl"}
    >
      <ConnectSSODrawerContent />
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  drawerContainer: {
    backgroundColor: theme.palette.groovy.grey[100],
    padding: 0,
    "& > *": {
      margin: "0 auto",
    },
  },
}))
export default ConnectSSODrawer
