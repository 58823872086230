/**
 * @generated SignedSource<<806bd3d266da7ad5ee1fff52f96779e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
export type ModuleSelect_OrganizationQuery$variables = {
  productId: string;
  organizationId: string;
  isProduct: boolean;
  isOrganization: boolean;
  entities?: ReadonlyArray<ContentUsageEntity> | null;
};
export type ModuleSelect_OrganizationQuery$data = {
  readonly organization?: {
    readonly modules?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"ModuleDropdownItem_ContentFragment">;
        };
      }>;
    };
  } | null;
  readonly product?: {
    readonly modules?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"ModuleDropdownItem_ContentFragment">;
        };
      }>;
    };
  } | null;
};
export type ModuleSelect_OrganizationQuery = {
  variables: ModuleSelect_OrganizationQuery$variables;
  response: ModuleSelect_OrganizationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entities"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isOrganization"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isProduct"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v6 = {
  "kind": "Variable",
  "name": "entities",
  "variableName": "entities"
},
v7 = [
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "excludeProductModules",
    "value": true
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContentNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModuleDropdownItem_ContentFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v11 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v12 = [
  (v6/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaUrl",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContentNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Content",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v14/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentUsageNodeConnection",
            "kind": "LinkedField",
            "name": "usages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentUsageNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Collection",
                        "kind": "LinkedField",
                        "name": "collection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductApp",
                            "kind": "LinkedField",
                            "name": "app",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "customAppTitle",
                                "storageKey": null
                              },
                              (v14/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModuleSelect_OrganizationQuery",
    "selections": [
      {
        "condition": "isOrganization",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "organization",
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "ContentNodeConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "Organization",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isProduct",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "product",
            "args": (v11/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "ContentNodeConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "Product",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModuleSelect_OrganizationQuery",
    "selections": [
      {
        "condition": "isOrganization",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "organization",
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "ContentNodeConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "Organization",
                "abstractKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isProduct",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "product",
            "args": (v11/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "ContentNodeConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "Product",
                "abstractKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "18e1fc15b51e3624f0d9e31321aae80a",
    "id": null,
    "metadata": {},
    "name": "ModuleSelect_OrganizationQuery",
    "operationKind": "query",
    "text": "query ModuleSelect_OrganizationQuery(\n  $productId: ID!\n  $organizationId: ID!\n  $isProduct: Boolean!\n  $isOrganization: Boolean!\n  $entities: [ContentUsageEntity!]\n) {\n  organization: node(id: $organizationId) @include(if: $isOrganization) {\n    __typename\n    ... on Organization {\n      modules(excludeProductModules: true, entities: $entities) {\n        edges {\n          node {\n            id\n            name\n            ...ModuleDropdownItem_ContentFragment\n          }\n        }\n      }\n    }\n    id\n  }\n  product: node(id: $productId) @include(if: $isProduct) {\n    __typename\n    ... on Product {\n      modules(entities: $entities) {\n        edges {\n          node {\n            id\n            name\n            ...ModuleDropdownItem_ContentFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment ModuleDropdownItem_ContentFragment on Content {\n  name\n  product {\n    name\n    badge {\n      ...BadgeFragment\n      id\n    }\n    id\n  }\n  usages {\n    edges {\n      node {\n        id\n        entity\n        collection {\n          app {\n            customAppTitle\n            badge {\n              ...BadgeFragment\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0385ab9c0bf572fdefcd9f163d37fcb6";

export default node;
