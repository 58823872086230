import ROUTE_NAMES from "@/core/route/util/routeNames"
import LockIcon from "@/core/ui/images/empty-state-illustrations/lock.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MetaDetails from "@/main/page/MetaDetails"
import { DiscoButton, DiscoEmptyState, DiscoLink, DiscoText } from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"

function AdminPlanPausedPage() {
  return (
    <MetaDetails>
      <AdminPlanPausedContent />
    </MetaDetails>
  )
}

export function AdminPlanPausedContent() {
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"))

  return (
    <div className={classes.root}>
      <DiscoEmptyState
        testid={"AdminPlanPausedContent"}
        icon={<LockIcon />}
        title={"Your plan has been paused at your request"}
        subtitle={
          "Don't worry! Your progress is safe and you could come back to right where you left off. Please select a best fitting plan to continue building your live learning empire."
        }
        variant={"page"}
        buttons={
          <DiscoButton
            to={{
              pathname: ROUTE_NAMES.ADMIN.SETTINGS.BILLING.ROOT,
              state: { organzationPlanSelectionModal: "open" },
            }}
            data-testid={"AdminPlanPausedContent.select-plan-button"}
          >
            {"Select a Plan"}
          </DiscoButton>
        }
        footer={
          <DiscoText align={"left"} variant={"body-sm"} color={"text.secondary"}>
            {"Or you can delete this community in "}
            <DiscoLink to={ROUTE_NAMES.ADMIN.SETTINGS.ADVANCED.ROOT}>
              <DiscoText variant={"body-sm"} component={"span"} color={"primary.main"}>
                {"Advanced Settings"}
              </DiscoText>
            </DiscoLink>
            {"."}
          </DiscoText>
        }
        align={"left"}
        flow={isMobile ? "column" : "row-reverse"}
      />
    </div>
  )
}

const useStyles = makeUseStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
})

export default AdminPlanPausedPage
