import { SelectOption } from "@disco-ui"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { ContentUsageCount } from "../__generated__/AdminContentLibraryListTablePaginationQuery.graphql"

type AdminContentListContentUsageCountOption = SelectOption<ContentUsageCount | null>

export type AdminContentListContentUsageCountFilterState = {
  contentUsageCount: AdminContentListContentUsageCountOption | null
}

interface Props {
  filterState: AdminContentListContentUsageCountFilterState
  setFilterState: (state: AdminContentListContentUsageCountFilterState) => void
}

export default function AdminContentListInstancesFilter({
  filterState,
  setFilterState,
}: Props) {
  const options: AdminContentListContentUsageCountOption[] = [
    {
      value: "one_or_more",
      title: "1 or More Instances",
    },
    {
      value: "none",
      title: "0 Instances",
    },
  ]

  const selectedOption = filterState.contentUsageCount

  return (
    <DiscoTableFilterDropdown
      testid={"AdminContentList.instances-select"}
      isNested
      options={options}
      onOptionSelect={handleSelect}
      selectedOption={selectedOption}
      dropdown={{ label: "# of Instances", icon: null }}
    />
  )

  function handleSelect(option: AdminContentListContentUsageCountOption | null) {
    if (option?.value)
      setFilterState({
        ...filterState,
        contentUsageCount: option,
      })
    else
      setFilterState({
        ...filterState,
        contentUsageCount: null,
      })
  }
}
