import PathwayGroupProductPopover, {
  PathwayGroupProductPopoverProps,
} from "@/admin/pathways/PathwayGroupProductPopover"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"

type PathwayGroupProductSelectButtonProps = {
  renderButton: OverridableDiscoButtonChildren
} & Pick<PathwayGroupProductPopoverProps, "form" | "groupIdx"> &
  TestIDProps

function PathwayGroupProductSelectButton({
  testid = "PathwayGroupProductSelectButton",
  renderButton,
  form,
  groupIdx,
}: PathwayGroupProductSelectButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <>
      <OverridableDiscoButton
        testid={testid}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        {renderButton}
      </OverridableDiscoButton>
      <PathwayGroupProductPopover
        testid={testid}
        anchorEl={anchorEl}
        onClose={handleClose}
        form={form}
        groupIdx={groupIdx}
      />
    </>
  )

  function handleClose() {
    setAnchorEl(null)
  }
}

export default PathwayGroupProductSelectButton
