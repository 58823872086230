import { AuthProviderIDP } from "@/admin/integrations/sso/__generated__/useCreateAuthProviderMutation.graphql"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"

export function useConnectSSODrawer() {
  const drawer = useGlobalDrawer("connectSSO")

  const open = (initialIdp?: AuthProviderIDP) => {
    drawer.open({
      isOpen: "true",
      selectedIdp: initialIdp,
    })
  }

  const close = () => {
    drawer.close()
  }

  const setStep = (step: number) => {
    drawer.setParams({ currentStep: step.toString() })
  }

  const setSelectedIdp = (idp: AuthProviderIDP) => {
    drawer.setParams({ selectedIdp: idp })
  }

  return {
    isOpen: drawer.params.isOpen === "true",
    currentStep: drawer.params.currentStep ? parseInt(drawer.params.currentStep, 10) : 1,
    selectedIdp: drawer.params.selectedIdp,
    open,
    close,
    setStep,
    setSelectedIdp,
  }
}
