import CreatePathwayModal from "@/admin/pathways/CreatePathwayModal"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton, DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { graphql } from "react-relay"
import { CreatePathwayButtonQuery } from "./__generated__/CreatePathwayButtonQuery.graphql"

type CreatePathwayButtonProps = OverridableDiscoButtonProps & TestIDProps

function CreatePathwayButton({
  children,
  testid = "CreatePathwayButton",
  ...rest
}: CreatePathwayButtonProps) {
  const activeOrganization = useActiveOrganization()!
  const adminCourseLabel = useLabel("admin_experience")
  const canAdd = activeOrganization.viewerPermissions.has("pathways.manage")

  const { organization } = Relay.useSkippableLazyLoadQuery<CreatePathwayButtonQuery>(
    graphql`
      query CreatePathwayButtonQuery($organizationId: ID!) {
        organization: node(id: $organizationId) {
          ... on Organization {
            pathwayProductOptions {
              totalCount
            }
          }
        }
      }
    `,
    { organizationId: activeOrganization.id },
    { skip: !canAdd }
  )

  if (!canAdd) return null
  const hasValidPathwayProducts = !!organization?.pathwayProductOptions?.totalCount
  const disabled = rest.disabled || !hasValidPathwayProducts

  return (
    <DiscoTooltip
      content={`Pathway creation requires at least one ${adminCourseLabel.singular} with Curriculum and no fixed duration.`}
      disabled={hasValidPathwayProducts}
    >
      <div>
        <OverridableDiscoButton
          testid={testid}
          {...rest}
          disabled={disabled}
          modal={({ isOpen, onClose }) => {
            // Reset form state on each open
            if (!isOpen) return null
            return <CreatePathwayModal onClose={onClose} />
          }}
        >
          {children}
        </OverridableDiscoButton>
      </div>
    </DiscoTooltip>
  )
}

export default Relay.withSkeleton({
  component: CreatePathwayButton,
  skeleton: DiscoButtonSkeleton,
})
