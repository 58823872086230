/**
 * @generated SignedSource<<4a28f423e0549d28881928f69f270320>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type ProductAppKind = "content" | "link" | "curriculum" | "events" | "members" | "resources" | "slack" | "collection" | "product_link" | "organization_events" | "organization_members" | "chat_channel" | "posts" | "nav_folder" | "%future added value";
export type ProductMembershipOrder = "nav" | "join_date" | "event_attendance" | "engagement_score" | "last_active" | "time_spent" | "%future added value";
export type ProductMembershipsCurriculumCompletionFilter = "ongoing" | "completed" | "%future added value";
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
export type CreateMemberProductMembershipsCSVDataInput = {
  organizationMembershipId: string;
  roles?: ReadonlyArray<ProductRole> | null;
  productType?: ProductType | null;
  isActiveOrUpcomingProduct?: boolean | null;
  orderBy?: ProductMembershipOrder | null;
  direction?: OrderDirection | null;
  appKind?: ProductAppKind | null;
  inProductsViewerIsAdminOf?: boolean | null;
  curriculumCompletion?: ProductMembershipsCurriculumCompletionFilter | null;
};
export type AdminMembersReportProductsTableExportCSVButtonMutation$variables = {
  input: CreateMemberProductMembershipsCSVDataInput;
};
export type AdminMembersReportProductsTableExportCSVButtonMutation$data = {
  readonly response: {
    readonly downloadCSVLink: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type AdminMembersReportProductsTableExportCSVButtonMutation = {
  variables: AdminMembersReportProductsTableExportCSVButtonMutation$variables;
  response: AdminMembersReportProductsTableExportCSVButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMemberProductMembershipsCSVDataResponse",
    "kind": "LinkedField",
    "name": "createMemberProductMembershipsCSVData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloadCSVLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminMembersReportProductsTableExportCSVButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminMembersReportProductsTableExportCSVButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4f4aee997248bc24fc16bdf4dec99e91",
    "id": null,
    "metadata": {},
    "name": "AdminMembersReportProductsTableExportCSVButtonMutation",
    "operationKind": "mutation",
    "text": "mutation AdminMembersReportProductsTableExportCSVButtonMutation(\n  $input: CreateMemberProductMembershipsCSVDataInput!\n) {\n  response: createMemberProductMembershipsCSVData(input: $input) {\n    downloadCSVLink\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "384a2c5d197da1aafb13a83771c23085";

export default node;
