import PathwayGroupProductOption from "@/admin/pathways/PathwayGroupProductOption"
import { PathwayGroupProductOptionsListPaginationQuery } from "@/admin/pathways/__generated__/PathwayGroupProductOptionsListPaginationQuery.graphql"
import { PathwayGroupProductOptionsListQuery } from "@/admin/pathways/__generated__/PathwayGroupProductOptionsListQuery.graphql"
import {
  PathwayGroupProductOptionsList_PaginationFragment$data,
  PathwayGroupProductOptionsList_PaginationFragment$key,
} from "@/admin/pathways/__generated__/PathwayGroupProductOptionsList_PaginationFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalID, NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoSpinner, DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import useDebounce from "@utils/hook/useDebounce"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect, useState } from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"

const PAGE_SIZE = 10

export type PathwayGroupProductSelectOption = NodeFromConnection<
  PathwayGroupProductOptionsList_PaginationFragment$data["pathwayProductOptions"]
>

type PathwayGroupProductOptionsListProps = {
  handleAddProduct: (product: PathwayGroupProductSelectOption) => void
  search: string
  notInProductIds: GlobalID[]
  loadOnScroll?: boolean
  publishedOnly?: boolean
} & TestIDProps

function PathwayGroupProductOptionsList({
  testid = "PathwayGroupProductOptionsList",
  handleAddProduct,
  search,
  notInProductIds,
  loadOnScroll = true,
  publishedOnly = false,
}: PathwayGroupProductOptionsListProps) {
  const activeOrganization = useActiveOrganization()
  const [debouncedSearch, setDebouncedSearch] = useState(search)
  const debounceSearch = useDebounce((value) => setDebouncedSearch(value), 500)

  useEffect(() => {
    debounceSearch(search)
  }, [search, debounceSearch])

  const { organization } = useLazyLoadQuery<PathwayGroupProductOptionsListQuery>(
    graphql`
      query PathwayGroupProductOptionsListQuery(
        $organizationId: ID!
        $notInProductIds: [ID!]
        $search: String
        $status: ProductStatus
        $first: Int!
        $after: String
      ) {
        organization: node(id: $organizationId) {
          ... on Organization {
            id
            ...PathwayGroupProductOptionsList_PaginationFragment
              @arguments(
                search: $search
                notInProductIds: $notInProductIds
                status: $status
                first: $first
                after: $after
              )
          }
        }
      }
    `,
    {
      organizationId: activeOrganization?.id ?? "",
      notInProductIds,
      search: debouncedSearch,
      status: publishedOnly ? "published" : undefined,
      first: PAGE_SIZE,
    },
    { fetchPolicy: "store-or-network" }
  )

  const { data, loadNext, isLoadingNext, hasNext } = usePaginationFragment<
    PathwayGroupProductOptionsListPaginationQuery,
    PathwayGroupProductOptionsList_PaginationFragment$key
  >(
    graphql`
      fragment PathwayGroupProductOptionsList_PaginationFragment on Organization
      @refetchable(queryName: "PathwayGroupProductOptionsListPaginationQuery")
      @argumentDefinitions(
        search: { type: "String" }
        notInProductIds: { type: "[ID!]" }
        status: { type: "ProductStatus" }
        first: { type: "Int!" }
        after: { type: "String" }
      ) {
        pathwayProductOptions(
          search: $search
          notInProductIds: $notInProductIds
          status: $status
          first: $first
          after: $after
        ) @connection(key: "PathwayGroupProductOptionsList_pathwayProductOptions") {
          __id
          edges {
            node {
              id
              name
              badge {
                ...BadgeFragment @relay(mask: false)
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const products = Relay.connectionToArray(data?.pathwayProductOptions)

  if (!products.length) return <DiscoText>{"No products."}</DiscoText>

  return (
    <>
      {products.map((product, idx) => {
        return (
          <DiscoDropdownItem
            key={product.id}
            testid={`${testid}.dropdown-item.${idx}`}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleAddProduct(product)
            }}
          >
            <PathwayGroupProductOption
              testid={testid}
              option={{ id: product.id, name: product.name, badge: product.badge }}
            />
          </DiscoDropdownItem>
        )
      })}
      {hasNext && loadOnScroll && (
        <DiscoScrolledIntoView
          onScrolledIntoView={handleScrolledIntoView}
          isLoading={isLoadingNext}
        />
      )}
    </>
  )

  function handleScrolledIntoView() {
    if (!hasNext || isLoadingNext) return
    loadNext(PAGE_SIZE)
  }
}

export default Relay.withSkeleton({
  component: PathwayGroupProductOptionsList,
  skeleton: () => <DiscoSpinner />,
})
