import { useAssetSubtitlesModal } from "@/admin/asset/tracks/AssetSubtitlesModalContext"
import AssetTracksListItem, {
  AssetTracksListItemSkeleton,
} from "@/admin/asset/tracks/AssetTracksListItem"
import { AssetTracksListPaginationQuery } from "@/admin/asset/tracks/__generated__/AssetTracksListPaginationQuery.graphql"
import { AssetTracksListQuery } from "@/admin/asset/tracks/__generated__/AssetTracksListQuery.graphql"
import { AssetTracksList_PaginationFragment$key } from "@/admin/asset/tracks/__generated__/AssetTracksList_PaginationFragment.graphql"
import FolderIcon from "@/core/ui/images/empty-state-illustrations/folder.svg"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoEmptyState,
  DiscoTable,
  DiscoTableSkeletonWithHeader,
  DiscoTextButton,
} from "@disco-ui"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { useState } from "react"
import {
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { graphql } from "relay-runtime"

export const ASSET_TRACKS_PER_PAGE = 100 // No limit

export interface AssetTracksListProps {
  assetId: GlobalID
  showLanguageColumn?: boolean | null
  assetTrackIds?: GlobalID[] | null
  hideAddSubtitleButton?: boolean | null
  hideUpdateButtonFromOverflow?: boolean | null
}

function AssetTracksList({
  assetId,
  showLanguageColumn = true,
  assetTrackIds,
  hideAddSubtitleButton,
  hideUpdateButtonFromOverflow,
}: AssetTracksListProps) {
  const [activePage, setActivePage] = useState(1)

  const classes = useStyles()

  const { node } = useLazyLoadQuery<AssetTracksListQuery>(
    graphql`
      query AssetTracksListQuery(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $assetId: ID!
        $assetTrackIds: [ID!]
      ) {
        node(id: $assetId) {
          __typename
          ... on Asset {
            ...AssetTracksList_PaginationFragment
              @arguments(
                first: $first
                after: $after
                last: $last
                before: $before
                assetTrackIds: $assetTrackIds
              )
          }
        }
      }
    `,
    {
      first: ASSET_TRACKS_PER_PAGE,
      assetId,
      assetTrackIds,
    },
    { fetchPolicy: "network-only" }
  )

  const { data, refetch } = usePaginationFragment<
    AssetTracksListPaginationQuery,
    AssetTracksList_PaginationFragment$key
  >(
    graphql`
      fragment AssetTracksList_PaginationFragment on Asset
      @refetchable(queryName: "AssetTracksListPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
        assetTrackIds: { type: "[ID!]" }
      ) {
        id
        hasSubtitles
        autoGeneratedSubtitle {
          id
        }
        transcriptionJob {
          sieveJobErrorReason
        }
        subtitleTracks(
          first: $first
          after: $after
          last: $last
          before: $before
          assetTrackIds: $assetTrackIds
        ) @connection(key: "AssetTracksList__subtitleTracks") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...AssetTracksListItemFragment
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    Relay.isNodeType(node, "Asset") ? node : null
  )

  const { setModalState } = useAssetSubtitlesModal()
  const asset = Relay.narrowNodeType(node, "Asset")

  // if an asset track gets deleted then this asset gets invalidated
  useSubscribeToInvalidationState(data?.id ? [data.id] : [], refetchAsset)

  if (!asset || !data?.subtitleTracks) return null
  const assetTracks = Relay.connectionToArray(data.subtitleTracks)

  return (
    <>
      <DiscoTable
        sectionProps={{ padding: 0 }}
        emptyState={
          <DiscoEmptyState
            testid={"AssetTracksList.EmptyState"}
            icon={<FolderIcon />}
            title={"It's empty here!"}
            subtitle={"Once you add subtitles to this asset, they will appear here."}
            buttons={
              data.subtitleTracks.totalCount === 0 && (
                <DiscoButton leftIcon={"add"} onClick={goToCreateView}>
                  {"Subtitle"}
                </DiscoButton>
              )
            }
          />
        }
        activePage={activePage}
        setActivePage={setActivePage}
        rowsPerPage={ASSET_TRACKS_PER_PAGE}
        testid={"AssetTracksList.Table"}
        onPaginate={refetch}
        rows={assetTracks.map((assetTrack) => {
          return (
            <AssetTracksListItem
              key={assetTrack.id}
              assetTrackKey={assetTrack}
              showLanguageColumn={showLanguageColumn}
              hideUpdateButtonFromOverflow={hideUpdateButtonFromOverflow}
            />
          )
        })}
        connection={{
          cursorsList: data.subtitleTracks.edges.map((e) => e.cursor),
          totalCount: data.subtitleTracks.totalCount || 0,
          pageInfo: {
            endCursor: data.subtitleTracks.pageInfo.endCursor,
            startCursor: data.subtitleTracks.pageInfo.startCursor,
          },
        }}
        header={[
          ...ArrayUtils.spreadIf({ value: "Language" }, showLanguageColumn),
          { value: "File name" },
          { value: "Status" },
          { value: null, key: "actions" },
        ]}
        hideFooter
        columnWidths={[
          ...ArrayUtils.spreadIf("90px", showLanguageColumn),
          "100%",
          "80px",
          "60px",
        ]}
      />

      {!hideAddSubtitleButton && assetTracks.length > 0 && (
        <div className={classes.footerButtonsContainer}>
          <DiscoTextButton leftIcon={"add"} onClick={goToCreateView} variant={"grey"}>
            {"Add Subtitle"}
          </DiscoTextButton>
        </div>
      )}
    </>
  )

  function goToCreateView() {
    setModalState({ view: "create" })
  }

  function refetchAsset() {
    refetch(
      {
        first: ASSET_TRACKS_PER_PAGE,
        assetTrackIds,
      },
      { fetchPolicy: "network-only" }
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  footerButtonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export function AssetTracksListSkeleton({
  showLanguageColumn,
}: Pick<AssetTracksListProps, "showLanguageColumn">) {
  return (
    <DiscoTableSkeletonWithHeader
      header={[
        ...ArrayUtils.spreadIf({ value: "Language" }, showLanguageColumn),
        { value: "File name" },
        { value: "Status" },
        { value: null, key: "actions" },
      ]}
      rows={3}
      row={<AssetTracksListItemSkeleton />}
    />
  )
}

export default Relay.withSkeleton({
  component: AssetTracksList,
  skeleton: AssetTracksListSkeleton,
})
