import ExperienceSettingsLabel, {
  ExperienceSettingsLabelProps,
} from "@/product/settings/ExperienceSettingsLabel"

export default function PathwaySettingsLabel(props: ExperienceSettingsLabelProps) {
  return (
    <ExperienceSettingsLabel
      {...props}
      learnMoreUrl={
        // TODO: Add KB article link for pathway settings here when it exists
        props.learnMoreUrl || ""
      }
    />
  )
}
