import ConnectionHandler from "relay-connection-handler-plus"

import {
  ProductPhase,
  usePaginatedProductListPaginationQuery,
  usePaginatedProductListPaginationQuery$variables,
} from "@/admin/community/product-list/hooks/__generated__/usePaginatedProductListPaginationQuery.graphql"
import { usePaginatedProductListQuery } from "@/admin/community/product-list/hooks/__generated__/usePaginatedProductListQuery.graphql"
import { usePaginatedProductList_PaginationFragment$key } from "@/admin/community/product-list/hooks/__generated__/usePaginatedProductList_PaginationFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import {
  commitLocalUpdate,
  graphql,
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
interface Props {
  productPhases?: ProductPhase[]
  hideDrafts?: boolean
  viewerIsMember?: boolean | null
  type?: ProductType
}

function usePaginatedProductList({
  productPhases,
  hideDrafts,
  viewerIsMember,
  type = "course",
}: Props) {
  const activeOrganization = useActiveOrganization()!

  const { organization } = useLazyLoadQuery<usePaginatedProductListQuery>(
    graphql`
      query usePaginatedProductListQuery(
        $organizationId: ID!
        $first: Int!
        $after: String
        $phases: [ProductPhase!]
        $hideDrafts: Boolean
        $viewerIsMember: Boolean
        $type: String!
      ) {
        organization: node(id: $organizationId) {
          ... on Organization {
            ...usePaginatedProductList_PaginationFragment
              @arguments(
                first: $first
                after: $after
                phases: $phases
                hideDrafts: $hideDrafts
                viewerIsMember: $viewerIsMember
                type: $type
              )
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      phases: productPhases,
      hideDrafts,
      viewerIsMember,
      first: usePaginatedProductList.PAGE_SIZE,
      type,
    },
    { fetchPolicy: "store-or-network" }
  )

  const response = usePaginationFragment<
    usePaginatedProductListPaginationQuery,
    usePaginatedProductList_PaginationFragment$key
  >(
    graphql`
      fragment usePaginatedProductList_PaginationFragment on Organization
      @refetchable(queryName: "usePaginatedProductListPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        phases: { type: "[ProductPhase!]" }
        hideDrafts: { type: "Boolean" }
        viewerIsMember: { type: "Boolean" }
        type: { type: "String!" }
      ) {
        products(
          first: $first
          after: $after
          type: $type
          phases: $phases
          hideDrafts: $hideDrafts
          viewerIsMember: $viewerIsMember
        ) @connection(key: "usePaginatedProductList__products") {
          __id
          totalCount
          edges {
            node {
              id
              ...ProductCardFragment
              ...PathwayCardFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  useSubscribeToInvalidationState(
    response?.data?.products ? [response?.data?.products.__id] : [],
    () => {
      response.refetch({
        phases: productPhases,
        hideDrafts,
        viewerIsMember,
        first: usePaginatedProductList.PAGE_SIZE,
      })
    }
  )

  return response
}

namespace usePaginatedProductList {
  export const PAGE_SIZE = 18
  export function invalidatePathwaysConnections(organizationId: GlobalID) {
    // get the pathways product connections
    commitLocalUpdate(RelayEnvironment, (store) => {
      const organizationRecord = store.get(organizationId)
      if (!organizationRecord) return
      ConnectionHandler.getConnections(
        organizationRecord,
        "usePaginatedProductList__products",
        (variables) => {
          const { type } = variables as usePaginatedProductListPaginationQuery$variables
          return type === "pathway"
        }
      ).forEach((connection) => connection.invalidateRecord())
    })
  }
}

export default usePaginatedProductList
