import PathwayAccessRadioGroup from "@/admin/pathways/settings/PathwayAccessRadioGroup"
import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import ExperienceSettingsMetaDetailsFormFields from "@/product/settings/ExperienceSettingsMetaDetailsFormFields"
import { DiscoDivider } from "@disco-ui"
import { observer } from "mobx-react-lite"

type Props = {
  form: PathwaySettingsFormStore
}

function PathwaySettingsAvailability({ form }: Props) {
  return (
    <>
      <PathwayAccessRadioGroup form={form} />
      <DiscoDivider marginTop={0} marginBottom={1.5} />
      <ExperienceSettingsMetaDetailsFormFields form={form} productType={"pathway"} />
    </>
  )
}

export default observer(PathwaySettingsAvailability)
