import { CreatePathwayFormState } from "@/admin/pathways/hooks/useCreatePathwayForm"
import PathwayGroupProductOptionsList, {
  PathwayGroupProductSelectOption,
} from "@/admin/pathways/PathwayGroupProductOptionsList"
import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { DiscoAlert, DiscoIcon, DiscoInput, DiscoText, DiscoTooltip } from "@disco-ui"
import { Popover, Theme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"

export type PathwayGroupProductPopoverProps = {
  form: CreatePathwayFormState | PathwaySettingsFormStore
  groupIdx: number
  anchorEl: HTMLElement | null
  onClose: () => void
} & TestIDProps

function PathwayGroupProductPopover({
  testid = "PathwayGroupProductPopover",
  form,
  groupIdx,
  anchorEl,
  onClose,
}: PathwayGroupProductPopoverProps) {
  const [search, setSearch] = useState("")
  const experienceLabel = useLabel("admin_experience")
  const notInProductIds = Object.keys(form.state.productCache)

  const classes = useStyles()
  const isPublished = "status" in form.state && form.state.status === "published"

  return (
    <Popover
      data-testid={testid}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      keepMounted
      PaperProps={{ className: classes.container }}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          <DiscoText
            color={"text.secondary"}
          >{`Select ${experienceLabel.plural}`}</DiscoText>
          <DiscoTooltip
            content={`Only ${experienceLabel.plural} with a curriculum and no fixed duration can be added to a pathway.`}
          />
        </div>
        <DiscoInput
          data-testid={`${testid}.search`}
          startAdornment={<DiscoIcon icon={"search"} paddingRight={0.5} />}
          value={search}
          onChange={(e) => {
            e.stopPropagation()
            setSearch(e.target.value)
          }}
          placeholder={"Search"}
          fullWidth
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          // never show error state for popover parent in search input
          error={false}
        />
      </div>
      <ScrollShadowContainer classes={{ scrollContainer: classes.scrollContainer }}>
        {isPublished && (
          <DiscoAlert
            marginBottom={1}
            message={`Only published ${experienceLabel.plural} can be added to a published Pathway.`}
          />
        )}
        <PathwayGroupProductOptionsList
          testid={testid}
          handleAddProduct={handleAddProduct}
          search={search}
          notInProductIds={notInProductIds}
          publishedOnly={isPublished}
        />
      </ScrollShadowContainer>
    </Popover>
  )

  function handleAddProduct(product: PathwayGroupProductSelectOption) {
    form.state.productCache[product.id] = {
      name: product.name,
      badge: product.badge!,
    }
    form.state.pathwayGroups[groupIdx].productIds.push(product.id)
    handleClose()
  }

  function handleClose() {
    onClose()
    setSearch("")
  }
}

const useStyles = makeUseStyles((theme: Theme) => ({
  container: {
    height: "90vh",
    maxHeight: "416px",
    width: "90vw",
    maxWidth: "626px",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    borderRadius: theme.measure.borderRadius.big,
  },
  scrollContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    padding: theme.spacing(0, 2),
  },
  header: {
    padding: theme.spacing(2, 2, 1.5),
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    gap: theme.spacing(1),
  },
}))

export default observer(PathwayGroupProductPopover)
