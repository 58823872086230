import CreateBadgeModalButton from "@/admin/experiences/badges/CreateBadgeModalButton"
import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import PathwaySettingsLabel from "@/admin/pathways/settings/PathwaySettingsLabel"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ExperienceSettingsStatusRadioGroup from "@/product/settings/ExperienceSettingsStatusRadioGroup"
import ProductSlugInput from "@/product/settings/ProductSlugInput"
import { ExperienceSettingsStatusRadioGroupFragment$key } from "@/product/settings/__generated__/ExperienceSettingsStatusRadioGroupFragment.graphql"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoFormControlSkeleton,
  DiscoInput,
  DiscoLink,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoImageFormField from "@disco-ui/file/DiscoImageFormField"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { range } from "@utils/array/arrayUtils"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { generatePath } from "react-router-dom"

type Props = TestIDProps & {
  form: PathwaySettingsFormStore
  productKey: ExperienceSettingsStatusRadioGroupFragment$key
}

function PathwaySettingsDetails(props: Props) {
  const { testid = "PathwaySettingsDetails", form, productKey } = props
  const adminPathwayLabel = useAdminProductLabel("pathway")

  return (
    <>
      <DiscoFormControl
        variant={"two-column"}
        marginBottom={1.5}
        label={<PathwaySettingsLabel title={"Badge and Title"} />}
        sublabel={"A short title for this Pathway used for left navigation."}
        errorMessages={form.errorsByField.name || form.errorsByField.badgeId}
      >
        <DiscoInput
          data-testid={`${testid}.name`}
          value={form.state.name}
          onChange={(e) => (form.state.name = e.target.value)}
          fullWidth
          startAdornment={
            <CreateBadgeModalButton
              badge={form.state.badge!}
              testid={`${testid}.badge-picker`}
            />
          }
        />
      </DiscoFormControl>
      <DiscoFormControl
        variant={"two-column"}
        marginBottom={1.5}
        label={<PathwaySettingsLabel title={"Description"} />}
        sublabel={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {`This short description will be displayed on the ${adminPathwayLabel.singular} cards on the 'Explore' page. You can include a more robust description on your `}
            <DiscoLink
              target={"_blank"}
              to={generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
                productSlug: form.initialState.slug!,
              })}
              textVariant={"body-sm"}
            >
              {"Registration Page"}
            </DiscoLink>
            {"."}
          </DiscoText>
        }
        errorMessages={form.errorsByField.description}
      >
        <DiscoInput
          data-testid={`${testid}.description`}
          value={form.state.description}
          onChange={(e) => (form.state.description = e.target.value)}
          fullWidth
          multiline
        />
      </DiscoFormControl>
      <DiscoFormControl
        variant={"two-column"}
        marginBottom={1.5}
        label={<PathwaySettingsLabel title={"Cover Image"} />}
        sublabel={`Add an image for use on ${adminPathwayLabel.singular} cards, registration, social share and more!`}
        errorMessages={form.errorsByField.cover}
      >
        <DiscoImageFormField
          value={form.state.cover}
          onChange={(result) => {
            form.state.cover = result?.url || null
            form.state.coverAssetId = result?.id
          }}
          disableRemove
          suggestedDimensions={{ width: 1200, height: 600 }}
          cropperProps={{ stencilProps: { aspectRatio: ASPECT_RATIOS.COVER_PHOTO } }}
          alterAspectRatio={false}
        />
      </DiscoFormControl>
      <DiscoFormControl
        marginBottom={1.5}
        label={<PathwaySettingsLabel title={"URL"} />}
        sublabel={"The base URL where your Pathway lives."}
        errorMessages={form.errorsByField.slug}
      >
        <ProductSlugInput
          testid={testid}
          value={form.state.slug}
          onChange={(e) => (form.state.slug = e.target.value)}
        />
      </DiscoFormControl>
      <DiscoDivider marginTop={0} marginBottom={1.5} />
      <ExperienceSettingsStatusRadioGroup form={form} productKey={productKey} />
    </>
  )
}

const useStyles = makeUseStyles({
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
  },
})

export function PathwaySettingsDetailsSkeleton() {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <div className={classes.skeletonContainer}>
      {range(3).map((i) => (
        <DiscoFormControlSkeleton key={i} variant={"two-column"} marginBottom={1.5} />
      ))}
      <DiscoDivider marginBottom={1.5} />
      <DiscoTextSkeleton width={100} />
      <DiscoTextSkeleton width={400} marginBottom={1.5} />
      <Skeleton
        variant={"rect"}
        width={"100%"}
        height={150}
        style={{ borderRadius: theme.measure.borderRadius.big }}
      />
    </div>
  )
}

export default observer(PathwaySettingsDetails)
