import Badge from "@/admin/experiences/badges/Badge"
import { PathwayGroupProductSelectOption } from "@/admin/pathways/PathwayGroupProductOptionsList"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

type Props = {
  option: PathwayGroupProductSelectOption
} & TestIDProps

function PathwayGroupProductOption({
  testid = "PathwayGroupProductOption",
  option: { name, badge },
}: Props) {
  const classes = useStyles()
  return (
    <div data-testid={testid} className={classes.item}>
      <Badge badgeKey={null} badgeData={badge!} className={classes.badge} />
      <div>
        <DiscoText data-testid={`${testid}.product.${name}`} variant={"body-md-600"}>
          {name}
        </DiscoText>
        {/* for now, all options in this list are no fixed duration, future this should be dynamic based on product start/end */}
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {"No fixed duration"}
        </DiscoText>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    display: "grid",
    alignItems: "center",
    gap: theme.spacing(1.5),
    gridTemplateColumns: "48px 1fr",
    gridTemplateRows: "48px",
  },
  badge: {
    height: "100%",
    width: "100%",
  },
}))

export default PathwayGroupProductOption
