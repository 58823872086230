import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const PathaySettingsDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "pathway-settings-drawer-content" */ "@/admin/pathways/settings/PathwaySettingsDrawerContent"
    )
)

export default function PathwaySettingsDrawer() {
  const drawer = useGlobalDrawer("pathwaySettings")
  const classes = useStyles()
  const modal = useInitUnsavedChangesModalContext()

  return (
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer
        title={"Settings"}
        open={drawer.isOpen}
        onClose={() => modal.handleLeave({ onLeave: drawer.close })}
        size={"large"}
        containerClasses={{ drawerContainer: classes.drawerContainer }}
      >
        {drawer.params.pathwayProductId && <PathaySettingsDrawerContent />}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )
}

const useStyles = makeUseStyles({
  drawerContainer: {
    padding: 0,
  },
})
