import { ContentSelectQuery } from "@/admin/content-library/actions/__generated__/ContentSelectQuery.graphql"
import { ContentType } from "@/content-usage/__generated__/ContentUsageUtils_useNavigateToNextContentUsageFragment.graphql"
import ContentThumbnailWithDetails from "@/content/detail/ContentThumbnailWithDetails"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoInputSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
} from "@disco-ui/select/DiscoMultiSelect"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { AutocompleteGetTagProps } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

type Props = TestIDProps & {
  values: GlobalID[]
  onSelect: (contentIds: GlobalID[]) => void
  placeholder?: string
  typesToExclude?: ContentType[]
  limit?: number
}

function ContentSelect(props: Props) {
  const { testid = "ContentSelect", values, onSelect, typesToExclude, limit } = props

  const activeOrganization = useActiveOrganization()!
  const { organization } = useLazyLoadQuery<ContentSelectQuery>(
    graphql`
      query ContentSelectQuery($id: ID!, $excludedTypes: [ContentType!]) {
        organization: node(id: $id) {
          ... on Organization {
            adminLibraryContents(excludedTypes: $excludedTypes) {
              edges {
                node {
                  id
                  name
                  ...ContentThumbnailWithDetails_ContentFragment
                }
              }
            }
          }
        }
      }
    `,
    { id: activeOrganization.id, excludedTypes: typesToExclude }
  )

  const contents = Relay.connectionToArray(organization?.adminLibraryContents)
  const theme = useTheme()

  return (
    <DiscoMultiSelect
      testid={`${testid}.select`}
      placeholder={"Select content"}
      values={values}
      options={contents.map((content) => ({
        value: content.id,
        title: content.name || "",
        contentKey: content,
      }))}
      renderOption={(option) => {
        return (
          <DiscoDropdownItem
            title={
              <ContentThumbnailWithDetails
                testid={`${testid}.${option.title}`}
                contentKey={contents.find((c) => c.id === option.value)!}
                truncateText={1}
              />
            }
          />
        )
      }}
      renderTags={renderTags}
      onChange={(ids) => onSelect(ids)}
      limit={limit}
      filterOptions={{ maxVisible: 100 }}
    />
  )

  function renderTags(
    value: DiscoMultiSelectOption[],
    getTagProps: AutocompleteGetTagProps
  ) {
    return value.map((o, index) => (
      <DiscoTag
        key={o.value}
        {...getTagProps({ index })}
        testid={`${testid}.option.remove.${o.title}`}
        name={o.title}
        backgroundColor={theme.palette.common.white}
      />
    ))
  }
}

function ContentSelectSkeleton() {
  return <DiscoInputSkeleton />
}

export default Relay.withSkeleton({
  component: ContentSelect,
  skeleton: ContentSelectSkeleton,
})
